<template>
  <v-card>
    <v-card-title>
      Substeps:
    </v-card-title>

    <v-card-text>
      <v-expansion-panels v-model="panel">
        <draggable v-model="steps"
                   :sort="steps.length > 1"
                   class="flex"
                   @change="moveSubStep"
        >
          <template v-for="(substep, index) in steps">
            <v-expansion-panel
                :key="'substep_' + index"
            >
              <v-expansion-panel-header disable-icon-rotate>
                <v-icon v-if="steps.length > 1"
                        :class="[$style.dragIcon, 'float-left']">
                  mdi-drag-vertical
                </v-icon>
                <span>{{ substep | substepName }}</span>

                <template v-slot:actions>
                  <v-btn icon small class="mt-2 ml-1" @click.stop.prevent="deleteSubstep(index)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <tooltips-list v-bind:tooltips.sync="substep.Tooltips" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </draggable>
      </v-expansion-panels>
    </v-card-text>
    
    <v-card-actions class="justify-center">
      <v-btn small
             class="mt-5"
             @click="addSubstep">
        Add Substep
      </v-btn>
    </v-card-actions>
    <confirmation-dialog ref="confirmation" />
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import substepNameMixins from "@/mixins/substepNameMixins";
import TooltipsList from "@/components/TooltipsList";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  name: 'SubStepList',
  mixins: [substepNameMixins],
  components: {
    ConfirmationDialog,
    TooltipsList,
    draggable,
  },
  props: {
    substeps: {
      required: true,
      type: Array,
    },
    activeStep: {
      required: false,
      type: Number,
    },
  },
  computed: {
    steps: {
      get() {
        return this.substeps
      },
      set(newValue) {
        this.$emit('update:substeps', newValue);
      }
    },
    panel: {
      get() {
        return this.activeStep
      },
      set(newValue) {
        this.$emit('update:activeStep', newValue);
      }
    },
  },
  methods: {
    /**
     * Moving SubStep handler.
     * 
     * @param {Object} dragData
     */
    moveSubStep(dragData) {
      if (this.panel !== undefined) {
        if (this.panel === dragData.moved.oldIndex) {
          this.panel = dragData.moved.newIndex;
        } else if (dragData.moved.newIndex <= this.panel 
            && dragData.moved.oldIndex > this.panel
        ) {
          this.panel++;
        } else if (dragData.moved.newIndex >= this.panel
            && dragData.moved.oldIndex < this.panel) {
          this.panel--;
        }
      }
    },
    
    /**
     * Add new SubStep
     */
    addSubstep() {
      this.steps.push({
        "Tooltips": []
      });
    },
    
    /**
     * Click by delete substep button.
     * 
     * @param {Number} index - Index SubStep in array
     */
    deleteSubstep(index) {
      this.$refs.confirmation.showConfirm(
          'Do you really want to delete SubStep?',
          () => {
            this.steps.splice(index, 1);
          });
    }
  }
}
</script>

<style lang="scss" module>
.dragIcon {
  margin-right: 15px;
  max-width: 24px;
}
.title {
  font-size: 15px;
  font-weight: bold;
}
</style>