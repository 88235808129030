<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
  >
    <v-card>
      <v-card-title class="headline">
        Confirmation
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
        >
          No
        </v-btn>
        <v-btn
            color="red"
            text
            :loading="loading"
            @click="confirmed"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',

  data: () => ({
    dialog: false,
    text: null,
    callback: null,
    loading: false,
  }),
  
  methods: {
    /**
     * Load new json data.
     *
     * @param {String} text - Text of confirmation message
     * @param {Function} callback - Callback for confirmed action.
     */
    showConfirm(text, callback) {
      this.text = text;
      this.callback = callback;
      this.dialog = true;
    },

    /**
     * Confirmed handler.
     */
    async confirmed() {
      this.loading = true;
      try {
        await this.callback();
        this.dialog = false;
        this.$emit('confirmed');
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>