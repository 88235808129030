import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export const SET_JSON_DATA = 'SET_JSON_DATA';
export const SET_BACKGROUND = 'SET_BACKGROUND';

export default function createStore() {
    return new Vuex.Store({
        plugins: [
            createPersistedState({
                paths: ['jsonData'],
            }),
        ],
        state: {
            jsonData: [],
            background: null,
        },
        actions: {},
        mutations: {
            [SET_JSON_DATA]: (state, jsonData) => {
                state.jsonData = jsonData;
            },
            [SET_BACKGROUND]: (state, background) => {
                state.background = background;
            },
        },
        getters: {},
    });
}
