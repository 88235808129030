<template>
  <v-card tile flat>
    <v-card-title v-if="tooltipsList.length">
      Controller tooltips:
    </v-card-title>
    <v-card-text class="pl-0 pr-0">
      <draggable v-model="tooltipsList" class="flex" :sort="tooltipsList.length > 1">
        <template v-for="(tooltip, index) in tooltipsList">
          <v-card :key="'tooltip_' + index" :class="[$style.pointer, 'mb-5']" tile flat>
            <v-row>
              <v-col v-if="tooltipsList.length > 1" cols="1" class="pl-0 text-center">
                <v-icon class="mt-2">
                  mdi-drag-vertical
                </v-icon>
              </v-col>

              <v-col cols="2" class="pr-1 pl-0">
                <v-select
                    :items="controllerHandItems"
                    v-model="tooltip.ControllerHand"
                    label="Hand"
                    dense
                    outlined
                    hide-details
                ></v-select>
              </v-col>

              <v-col cols="4" class="pl-1 pr-1">
                <v-select
                    :items="controllerButtonItems"
                    v-model="tooltip.ControllerButton"
                    label="Button"
                    dense
                    outlined
                    hide-details
                ></v-select>
              </v-col>

              <v-col :cols="tooltipsList.length > 1 ? 4 : 5" class="pl-1 pr-0">
                <v-text-field
                    v-model="tooltip.Text"
                    label="Text"
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="pl-0 pr-0 text-center">
                <v-btn icon small class="mt-2 ml-1" @click="deleteTooltip(index)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </draggable>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn small
          @click="addTooltip">
        Add Tooltip
      </v-btn>
    </v-card-actions>
    <confirmation-dialog ref="confirmation" />
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  name: 'TooltipsList',
  components: {
    ConfirmationDialog,
    draggable,
  },
  props: {
    tooltips: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    controllerHandItems: [
      { text: 'L', value: 'Left'},
      { text: 'R', value: 'Right'},
      { text: 'B', value: 'Both'},
    ],
    controllerButtonItems: ['Touchpad', 'Trigger', 'ButtonEnter', 'ButtonOne', 'ButtonTwo', 'Grip'],
  }),
  computed: {
    tooltipsList: {
      get() {
        return this.tooltips
      },
      set(newValue) {
        this.$emit('update:tooltips', newValue);
      }
    },
  },
  methods: {
    /**
     * Add new Tooltip.
     */
    addTooltip() {
      this.tooltipsList.push({
        "ControllerHand": "Left",
        "ControllerButton": "Touchpad",
        "Text": ""
      });
    },

    /**
     * Delete tooltip by index in array.
     *
     * @param {Number} index - Index of tooltip in array.
     */
    deleteTooltip(index) {
      this.$refs.confirmation.showConfirm(
          'Do you really want to delete Tooltip?',
          () => {
            this.tooltipsList.splice(index, 1);
          });
    }
  }
}
</script>

<style lang="scss" module>
.pointer {
  cursor: pointer;
}

.title {
  font-size: 15px;
  font-weight: bold;
}
</style>