<template>
  <v-row>
    <input ref="uploader"
           class="d-none"
           type="file"
           accept="application/json"
           @change="onFileChanged" />
    <v-btn
        @click="openFile"
        class="mr-5"
        depressed
        :loading="isSelecting"
    >
      Load
    </v-btn>
    <v-btn
        class="mr-5"
        depressed
        color="primary"
        @click="downloadJson"
    >
      Save
    </v-btn>

    <v-btn
        depressed
        color="error"
        @click="clearJson"
    >
      Clear
    </v-btn>
    <confirmation-dialog ref="confirmation" />

    <v-dialog
        v-model="errorDialog"
        persistent
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Loading data error
        </v-card-title>
        <v-card-text v-html="errorMessage" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              text
              @click="errorDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import ConfirmationDialog from "@/components/ConfirmationDialog";
  export default {
    name: 'Toolbar',
    components: {ConfirmationDialog},
    data: () => ({
      isSelecting: false,
      errorDialog: false,
      errorMessage: '',
    }),
    props: {
      jsonData: {
        required: true,
        type: Array,
      },
    },
    methods: {

      /**
       * Show choice file dialog.
       */
      openFile() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.uploader.click()
      },

      /**
       * On file change handler.
       */
      onFileChanged(e) {
        const file = e.target.files[0];
        if (file === undefined) {
          return;
        }
        const jsonFile = new FileReader();

        jsonFile.onload = e => {
          let json = null;
          try {
            json = JSON.parse(e.target.result);
          } catch (exception) {
            this.showError(exception.message);
          }
          
          if (json && this.validateJson(json)) {
            this.$emit('jsonLoaded', json);
          }
        }
        jsonFile.readAsText(file);
        this.$refs.uploader.value = null;
      },

      /**
       * Show error message
       *
       * @param {string} message - Error message
       */
      showError(message) {
        this.errorDialog = true;
        this.errorMessage = message;
      },

      /**
       * Validate jsonData.
       * 
       * @param jsonData
       */
      validateJson(jsonData) {
        if (!Array.isArray(jsonData)) {
          this.showError('JSON file should contains data as array');
          return false;
        }
        const propertyMap = {
          StepName: 'string',
          Title: 'string',
          Message: 'string',
          SubSteps: 'array',
        };
        const errors = [];
        jsonData.forEach((step, index) => {
          Object.keys(propertyMap).forEach(field => {
            const type = propertyMap[field];
            if (Object.prototype.hasOwnProperty.call(step, field)) {
              if ((type !== 'array' && typeof step[field] !== type) 
                  || (type === 'array' && !Array.isArray(step[field]))
              ) {
                errors.push(`Field ${field} should by type ${type} in step with index: ${index}`);
              }
            } else {
              errors.push(`No field ${field} in step with index: ${index}`);
            }
          });
        });
        if (errors.length) {
          this.showError(errors.join('<br />'));
          return false;
        }
        return true;
      },

      /**
       * Clear JsonData
       */
      clearJson() {
        this.$refs.confirmation.showConfirm(
            'Do you really want to clear Tutorial steps?',
            () => {
              this.$emit('clear');
            });
      },

      /**
       * Download json data as json file.
       */
      downloadJson() {
        const data = JSON.stringify(this.jsonData, null, 2);
        const blob = new Blob([data], {type: 'text/plain'})
        const e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
        a.download = "tutorial.json";
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
        e.initEvent('click', true, false);
        a.dispatchEvent(e);
      },
    }
  }
</script>