<template>
  <v-app id="inspire" >
    <v-app-bar
        app
        color="white"
        flat
        fixed
    >
        <Toolbar :json-data="this.jsonData" 
                 class="pl-10"
                 @jsonLoaded="jsonLoad"
                 @clear="clear"
        />
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-flex class="mr-10 ml-10">
        <v-row>
          <v-col cols="3" class="pt-7">
            <div class="sticky-top">
              <TutorialSteps
                  ref="steps"
                  v-bind:json-data.sync="jsonData"
                  @selectStep="selectStep"
                  @selectSubStep="selectSubstep"
                  @unselect="unselect"
              />
            </div>
          </v-col>

          <v-col cols="5" class="pt-7">
            <StepProperties
                v-bind:step-data.sync="stepData"
                class="mb-5"
                @deleteStep="deleteStep"
            />
            <SubStepList v-if="stepData" 
                         v-bind:substeps.sync="stepData.SubSteps"
                         v-bind:active-step.sync="activeSubStep"
            />
          </v-col>

          <v-col cols="4" class="pt-7">
            <div class="sticky-top">
              <step-preview ref="stepPreview"
                            :step="stepData"
                            :total-steps="jsonData.length"
                            :active-step="stepIndex"
                            :active-sub-step="activeSubStep"
                            @showPreviousSubStep="activeSubStep--"
                            @showNextSubStep="activeSubStep++"
                            @showPreviousStep="$refs.steps.selectStep(stepIndex-1)"
                            @showNextStep="$refs.steps.selectStep(stepIndex+1)"
              />
            </div>
          </v-col>
        </v-row>
      </v-flex>
    </v-main>
  </v-app>
</template>

<script>
  import { mapMutations } from 'vuex';
  import TutorialSteps from '../components/TutorialSteps';
  import StepProperties from "@/components/StepProperties";
  import SubStepList from "@/components/SubStepList";
  import { SET_JSON_DATA, SET_BACKGROUND } from '@/store';
  import Toolbar from "@/components/Toolbar";
  import StepPreview from "@/components/StepPreview";

  export default {
    name: 'Home',
    components: {
      StepPreview,
      Toolbar,
      SubStepList,
      StepProperties,
      TutorialSteps,
    },
    data: () => ({
      stepData: null,
      stepIndex: undefined,
      activeSubStep: undefined,
    }),
    computed: {
      jsonData: {
        get() {
          return this.$store.state.jsonData;
        },
        set(value) {
          this.SET_JSON_DATA(value);
        },
      },
    },
    watch: {
      jsonData: {
        deep: true,
        handler(newValue) {
          this.jsonData = newValue;
        },
      },
      activeSubStep(newValue) {
        this.$refs.steps.selectSubStep(newValue);
      },
    },
    mounted() {
      const background = new Image();
      background.src = require('@/assets/joysticks.png');
      this.SET_BACKGROUND(background);
    },
    methods: {
      ...mapMutations([SET_JSON_DATA, SET_BACKGROUND]),
      
      /**
       * Select SubStep by index in array.
       * 
       * @param {Number} stepIndex - Index of step in array
       * @param {Number} subStepIndex - Index of SubStep in array.
       */
      selectSubstep(stepIndex, subStepIndex) {
        this.stepIndex = stepIndex;
        this.stepData = this.jsonData[stepIndex];
        this.activeSubStep = undefined;
        this.activeSubStep = subStepIndex;
      },

      /**
       * Select step by index in array.
       *
       * @param {Number} stepIndex - Index of step in array
       */
      selectStep(stepIndex) {
        this.stepIndex = stepIndex;
        this.stepData = this.jsonData[stepIndex];
        this.activeSubStep = undefined;
        this.$refs.steps.selectSubStep(undefined);
      },

      /**
       * Unselect selected step.
       */
      unselect() {
        this.stepData = null;
        this.stepIndex = undefined;
        this.activeSubStep = undefined;
      },

      /**
       * Delete selected step.
       */
      deleteStep() {
        this.stepData = null;
        this.jsonData.splice(this.stepIndex, 1);
        this.activeSubStep = undefined;
        this.stepIndex = undefined;
        this.$refs.steps.selectStep(undefined);
      },

      /**
       * Clear json data.
       */
      clear() {
        this.jsonData = [];
        this.stepData = null;
        this.stepIndex = null;
        this.activeSubStep = undefined;
      },

      /**
       * Load new json data.
       *
       * @param {Array} json - Data of tutorial steps.
       */
      jsonLoad(json) {
        this.jsonData = json;
      },
    },
  }
</script>

<style lang="scss">

.sticky-top {
  position: sticky !important;
  top: 80px !important;
}
</style>