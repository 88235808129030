<template>
  <v-card min-height="200">
    <v-card-title>
      Preview
    </v-card-title>

    <v-card-text v-if="!step">
      <v-container>
        <v-row justify="center" align="center">
          <v-col>
            No step selected
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-else class="text-center">
      <div :class="[$style.title, 'mb-10']">{{ step.Title }}</div>
      <div :class="[$style.message, 'mb-10']">{{ step.Message }}</div>

      <visual-preview ref="visualPreview"
                      :tooltips="tooltips"/>
    </v-card-text>
    <v-card-actions v-if="step" class="justify-center">
      <v-btn
          v-if="activeSubStep === undefined
                || activeSubStep === 0"
          :disabled="activeStep === 0"
          small
          class="mt-5"
          @click="$emit('showPreviousStep')"
      >
        Previous Step
      </v-btn>
      
      <v-btn
          v-if="activeSubStep !== undefined 
                && step.SubSteps.length > 1
                && activeSubStep > 0"
          small
          class="mt-5"
          @click="$emit('showPreviousSubStep')"
      >
        Previous
      </v-btn>
      <v-btn
          v-if="activeSubStep !== undefined
                && step.SubSteps.length > 1 
                && activeSubStep < step.SubSteps.length-1"
          small
          class="mt-5"
          @click="$emit('showNextSubStep')"
      >
        Next
      </v-btn>
      <v-btn
          v-if="activeSubStep === undefined
                || activeSubStep === step.SubSteps.length-1
                || !step.SubSteps.length"
          :disabled="activeStep === totalSteps-1"
          small
          class="mt-5"
          @click="$emit('showNextStep')"
      >
        Next Step
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import VisualPreview from "@/components/VisualPreview";
export default {
  name: 'StepPreview',
  components: {VisualPreview},
  props: {
    step: {
      required: false,
      type: Object
    },
    totalSteps: {
      required: true,
      type: Number,
    },
    activeStep: {
      required: false,
      type: Number,
    },
    activeSubStep: {
      required: false,
      type: Number,
    },
  },
  computed: {
    tooltips() {
      return this.step
          && this.step.SubSteps.length
          && this.step.SubSteps[this.activeSubStep]
          && this.step.SubSteps[this.activeSubStep].Tooltips ? this.step.SubSteps[this.activeSubStep].Tooltips : null
    }
  }
}
</script>

<style lang="scss" module>
  .title {
    font-size: x-large;
  }
  .message {
    font-size: large;
  }
</style>