<template>
  <div id="visual-preview-container" :class="[$style['visual-preview'], 'justify-center']">
    <v-row class="tooltips ml-0 mr-0 mb-0">
      <template v-for="n in 4">
        <v-col cols="3" 
               class="tooltip-top-col pl-1 pr-1 pb-0"
               :key="'item_top_' + n"
               align-self="end"
        >
          <v-card
              v-if="tooltipTopSlots[n] && tooltipTopSlots[n].length"
              ref="tooltip-top-card"
              class="pa-2"
              outlined
          >
            <template v-for="(text, index) in tooltipTopSlots[n]">
              <div :key="'item_top_' + n + '_text_' + index">
                {{ text }}
                <v-divider v-if="index < tooltipTopSlots[n].length - 1"/>
              </div>
            </template>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"/>
    <v-row class="tooltips ml-0 mr-0 mt-0 pb-10">
      <template v-for="n in 4">
        <v-col cols="3" class="pl-1 pr-1 pt-0" :key="'item_bottom_' + n">
          <v-card
              v-if="tooltipBottomSlots[n] && tooltipBottomSlots[n].length"
              class="pa-2"
              outlined
          >
            <template v-for="(text, index) in tooltipBottomSlots[n]">
              <div :key="'item_top_' + n + '_text_' + index">
                {{ text }}
                <v-divider v-if="index < tooltipBottomSlots[n].length - 1"/>
              </div>
            </template>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'VisualPreview',
  data: () => ({
    init: false,
    ctx: null,
    containerWidth: null,
    background: null,
    controlPositions: {
      Left: {
        Touchpad: {x: 200, y: 166},
        Trigger: {x: 239, y: 264},
        ButtonEnter: {x: 147, y: 208},
        ButtonOne: {x: 197, y: 202},
        ButtonTwo: {x: 231, y: 197},
        Grip: {x: 166, y: 287},
      },
      Right: {
        Touchpad: {x: 624, y: 166},
        Trigger: {x: 584, y: 264},
        ButtonEnter: {x: 678, y: 208},
        ButtonOne: {x: 628, y: 202},
        ButtonTwo: {x: 593, y: 197},
        Grip: {x: 657, y: 287},
      },
    },
    tooltipTopSlots: {},
    tooltipBottomSlots: {},
    preferredPlaces: {
      Left: {
        Touchpad: {pos: 'top', index: [1, 2]},
        Trigger: {pos: 'bottom', index: [2, 1]},
        ButtonEnter: {pos: 'top', index: [1, 2]},
        ButtonOne: {pos: 'top', index: [1, 2]},
        ButtonTwo: {pos: 'top', index: [1, 2]},
        Grip: {pos: 'bottom', index: [1, 2]},
      },
      Right: {
        Touchpad: {pos: 'top', index: [4, 3]},
        Trigger: {pos: 'bottom', index: [3, 4]},
        ButtonEnter: {pos: 'top', index: [4, 3]},
        ButtonOne: {pos: 'top', index: [4, 3]},
        ButtonTwo: {pos: 'top', index: [4, 3]},
        Grip: {pos: 'bottom', index: [4, 3]},
      },
    },
    tooltipsPositions: {},
  }),
  props: {
    tooltips: {
      required: false,
      type: Array
    }
  },
  computed: {
    canvasHeight() {
      return this.background ? this.background.height * this.containerWidth / this.background.width : 0;
    },
    canvasWidth() {
      return this.containerWidth;
    },
  },
  mounted() {
    this.init = true;
    this.background = this.$store.state.background;
    this.clearTooltipSlots();
    this.initCanvas();
    this.calculateSizes();
  },
  updated() {
    if (this.init) {
      this.printTooltips();
      this.init = false;
    }
  },
  created() {
    window.addEventListener('resize', this.redraw);
  },
  destroyed() {
    window.removeEventListener('resize', this.redraw);
  },
  watch: {
    tooltips: {
      deep: true,
      handler() {
        this.printTooltips();
      },
    },
  },
  methods: {
    /**
     * Clear tooltip text slots.
     */
    clearTooltipSlots() {
      this.tooltipTopSlots = {
        1: [],
        2: [],
        3: [],
        4: [],
      };
      this.tooltipBottomSlots = {
        1: [],
        2: [],
        3: [],
        4: [],
      };
    },

    /**
     * Redraw tooltips, used when user resize the window.
     */
    redraw() {
      this.calculateSizes();
      this.printTooltips();
    },

    /**
     * Calculate container width.
     */
    calculateSizes() {
      const container = document.getElementById('visual-preview-container');
      this.containerWidth = container.offsetWidth;
    },

    /**
     * Initialize canvas object.
     */
    initCanvas() {
      this.ctx = this.$refs.canvas.getContext('2d');
      this.ctx.lineWidth = 1;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = 'black';
    },

    /**
     * Fresh canvas and tooltip text slots.
     */
    fresh() {
      const width = parseInt(this.canvasWidth);
      const height = parseInt(this.canvasHeight);
      this.ctx.clearRect(0, 0, width, height);
      this.ctx.drawImage(this.background, 0, 0, width, height);
      this.clearTooltipSlots();
    },

    /**
     * Print all tooltips.
     */
    printTooltips() {
      this.fresh();
      if (!this.tooltips) {
        return;
      }
      const that = this;
      this.prepareTooltipsPositions();
      this.tooltips.forEach((tooltip, tooltipIndex) => {
        that.printTooltip(tooltip, that.tooltipsPositions[tooltipIndex]);
      });
    },

    /**
     * Get coordinates of tooltip text.
     *
     * @param {Object} slot - Slot object for tooltip text, contains position and index of slot
     */
    getTooltipCoords(slot) {
      const y = slot.pos === 'top' ? 0 : this.canvasHeight;
      let x = 0;
      const slotWidth = this.canvasWidth / 4;
      x = (slotWidth * slot.index) - (slotWidth / 2);
      return {x, y};
    },

    /**
     * Get coordinates of button.
     *
     * @param {string} hand - Hand Left or Right
     * @param {string} button - Button on controller
     */
    getButtonCoords(hand, button) {
      const origCoords = this.controlPositions[hand] && this.controlPositions[hand][button]
          ? this.controlPositions[hand][button]
          : {x: 0, y: 0};
      const x = this.canvasWidth * origCoords.x / this.background.width;
      const y = this.canvasHeight * origCoords.y / this.background.height;
      return {x, y};
    },

    /**
     * Find optimal position on tooltip text:
     * At first - fill preferred places,
     * Secondly - fill secondary preferred places,
     * Third - fill other empty places,
     * else - use already filled places like additional text in same tooltip.
     * 
     * Yes, this logic looks like complex, but I did not find way how to do this easier :(
     */
    prepareTooltipsPositions() {
      this.tooltipsPositions = {};

      let attempt = 0;
      let stop = false;
      const tooltips = this.tooltips;
      const that = this;
      const usedPlaces = {};
      const usedTooltips = {};
      const getHand = (hand) => {
        return hand !== 'Both' ? hand : 'Left';
      };
          
      while (Object.keys(usedTooltips).length < tooltips.length && !stop) {
        let noPreferred = true;
        
        // Fill preferred places
        tooltips.forEach((tooltip, tooltipIndex) => {
          if (usedTooltips[tooltipIndex]) {
            return;
          }
          const preferred = that.preferredPlaces[getHand(tooltip.ControllerHand)][tooltip.ControllerButton];
          if (attempt <= preferred.index.length-1) {
            noPreferred = false;
            const preferredIndex = preferred.index[attempt];
            const key =  `${preferred.pos}_${preferredIndex}`;
            if (!usedPlaces[key]) {
              usedPlaces[key] = true;
              that.tooltipsPositions[tooltipIndex] = { pos: preferred.pos, index: preferredIndex };
              usedTooltips[tooltipIndex] = true;
            }
          }
        });
        attempt = attempt + 1;
        if (noPreferred) {
          stop = true;
          // Fill other e`mpty places
          if (Object.keys(usedTooltips).length < tooltips.length) {
            tooltips.forEach((tooltip, tooltipIndex) => {
              if (usedTooltips[tooltipIndex]) {
                return;
              }
              const preferred = that.preferredPlaces[getHand(tooltip.ControllerHand)][tooltip.ControllerButton];
              
              const positions = [preferred.pos];
              positions.push(preferred.pos === 'top' ? 'bottom' : 'top');
              positions.forEach((pos) => {
                if (usedTooltips[tooltipIndex]) {
                  return;
                }
                for(var i = 1; i <= 4; i++) {
                  const key = `${pos}_${i}`;
                  if (!usedPlaces[key]) {
                    usedPlaces[key] = true;
                    that.tooltipsPositions[tooltipIndex] = { pos: pos, index: i };
                    usedTooltips[tooltipIndex] = true;
                    break;
                  }
                }
              });
            });
          }
          // Add additional tooltips to already used slots.
          if (Object.keys(usedTooltips).length < tooltips.length) {
            tooltips.forEach((tooltip, tooltipIndex) => {
              if (usedTooltips[tooltipIndex]) {
                return;
              }
              const preferred = that.preferredPlaces[getHand(tooltip.ControllerHand)][tooltip.ControllerButton];
              that.tooltipsPositions[tooltipIndex] = { pos: preferred.pos, index: preferred.index[0] };
            });
          }
        }
      }
    },

    /**
     * Print tooltip object on canvas, and show tooltip text.
     *
     * @param {Object} tooltip - Object of tooltip
     * @param {Object} slot - Slot object for tooltip text, contains position and index of slot
     */
    printTooltip(tooltip, slot) {
      const button = tooltip.ControllerButton;
      const tooltipCoords = this.getTooltipCoords(slot);
      
      const positionSlots = slot.pos === 'top' ? this.tooltipTopSlots : this.tooltipBottomSlots;
      positionSlots[slot.index].push(tooltip.Text);
      
      if (tooltip.ControllerHand === 'Both') {
        const buttonLeftCoords = this.getButtonCoords('Left', button);
        const buttonRightCoords = this.getButtonCoords('Right', button);
        this.printLine(buttonLeftCoords, tooltipCoords);
        this.printLine(buttonRightCoords, tooltipCoords);
      } else {
        const buttonCoords = this.getButtonCoords(tooltip.ControllerHand, button);
        this.printLine(buttonCoords, tooltipCoords);
      }
    },

    /**
     * Print line on canvas.
     *
     * @param {Object} buttonCoords - coordinates of button
     * @param {Object} tooltipCoords - coordinates of tooltip
     */
    printLine(buttonCoords, tooltipCoords) {
      this.ctx.beginPath();
      this.ctx.moveTo(buttonCoords.x, buttonCoords.y);
      this.ctx.lineTo(tooltipCoords.x, tooltipCoords.y);
      this.ctx.stroke();
      
      this.ctx.beginPath();
      this.ctx.arc(buttonCoords.x, buttonCoords.y, 1, 0, 2 * Math.PI, true);
      this.ctx.stroke();
    },
  }
}
</script>

<style lang="scss" module>
.visual-preview {
  width: 100%;
}
</style>

<style lang="scss">
.tooltips .v-card.v-sheet.theme--light {
  border-color: black;
  min-height: 42px;
}
.tooltips .tooltip-top-col {
  
}
.tooltip-top-col .v-card {

}
canvas {
  display: block;
}
</style>
