var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"200"}},[_c('v-card-title',[_vm._v(" Preview ")]),(!_vm.step)?_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_vm._v(" No step selected ")])],1)],1)],1):_c('v-card-text',{staticClass:"text-center"},[_c('div',{class:[_vm.$style.title, 'mb-10']},[_vm._v(_vm._s(_vm.step.Title))]),_c('div',{class:[_vm.$style.message, 'mb-10']},[_vm._v(_vm._s(_vm.step.Message))]),_c('visual-preview',{ref:"visualPreview",attrs:{"tooltips":_vm.tooltips}})],1),(_vm.step)?_c('v-card-actions',{staticClass:"justify-center"},[(_vm.activeSubStep === undefined
              || _vm.activeSubStep === 0)?_c('v-btn',{staticClass:"mt-5",attrs:{"disabled":_vm.activeStep === 0,"small":""},on:{"click":function($event){return _vm.$emit('showPreviousStep')}}},[_vm._v(" Previous Step ")]):_vm._e(),(_vm.activeSubStep !== undefined 
              && _vm.step.SubSteps.length > 1
              && _vm.activeSubStep > 0)?_c('v-btn',{staticClass:"mt-5",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('showPreviousSubStep')}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.activeSubStep !== undefined
              && _vm.step.SubSteps.length > 1 
              && _vm.activeSubStep < _vm.step.SubSteps.length-1)?_c('v-btn',{staticClass:"mt-5",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('showNextSubStep')}}},[_vm._v(" Next ")]):_vm._e(),(_vm.activeSubStep === undefined
              || _vm.activeSubStep === _vm.step.SubSteps.length-1
              || !_vm.step.SubSteps.length)?_c('v-btn',{staticClass:"mt-5",attrs:{"disabled":_vm.activeStep === _vm.totalSteps-1,"small":""},on:{"click":function($event){return _vm.$emit('showNextStep')}}},[_vm._v(" Next Step ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }