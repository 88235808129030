<template>
  <v-card min-height="200">
    <v-card-title>Step properties</v-card-title>
    <v-card-text>
      <v-container v-if="!stepData" fill-height>
        <v-row justify="center" align="center">
          <v-col>
            No steps selected
          </v-col>
        </v-row>
      </v-container>
      <div v-else>
        <v-text-field v-model="stepData.StepName" label="Name" />
        <v-text-field v-model="stepData.Title" label="Title" />
        <v-textarea v-model="stepData.Message" label="Message" outlined/>
        <v-textarea v-model="stepData.Notes" label="Notes" outlined/>
      </div>
    </v-card-text>
    <v-card-actions v-if="stepData">
      <v-spacer/>
      <v-btn small
             dark
             color="red"
             @click="deleteStep">
        Delete Step
      </v-btn>
    </v-card-actions>
    <confirmation-dialog ref="confirmation" />
  </v-card>
</template>

<script>
  import ConfirmationDialog from "@/components/ConfirmationDialog";
  export default {
    name: 'StepProperties',
    components: {ConfirmationDialog},
    props: {
      stepData: {
        required: false,
        type: Object,
      },
    },
    methods: {
      /**
       * Click by delete step button.
       */
      deleteStep() {
        this.$refs.confirmation.showConfirm(
            'Do you really want to delete Step?',
            () => {
              this.$emit('deleteStep');
            });
      }
    }
  }
</script>
