import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import createStore from './store';

// Global styles
import './assets/theme/main.css';

Vue.config.productionTip = false

const store = createStore();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
