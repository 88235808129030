<template>
  <v-card min-height="200">
    <v-card-title>
      Tutorial steps
    </v-card-title>

    <v-card-text v-if="!jsonData.length">
      <v-container>
        <v-row justify="center" align="center">
          <v-col>
            No steps added
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-list v-else
            class="tutorial-steps" 
            dense
    >
      <v-list-item-group
          v-model="selectedStep"
          color="primary"
          class="items-list"
          :style="'max-height:' + listHeight + 'px'"
      >
        <draggable v-model="steps" @change="moveStep">
          <template v-for="(step, stepIndex) in steps">
            <v-list-group
                v-model="expandedItems[stepIndex]"
                :key="'step_' + stepIndex"
                :ripple="false"
                :group="'group_' + stepIndex"
                prepend-icon="mdi-drag-vertical"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ step.StepName }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item-group
                  v-model="activeSubSteps[stepIndex]"
                  color="primary"
              >
                <v-list-item
                    v-for="(substep, substepIndex) in step.SubSteps"
                    :key="'substep_' + stepIndex + '_' + substepIndex"
                    class="pl-10"
                    link
                    :ripple="false"
                    @click="clickSubStep(substepIndex)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="' '" />
                  </v-list-item-icon>

                  <v-list-item-title>{{ substep | substepName }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>

            </v-list-group>
          </template>
        </draggable>
      </v-list-item-group>
    </v-list>
    <v-card-actions class="justify-center">
      <v-btn
          small
          class="mt-5"
          @click="addStep"
      >
        Add Step
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import draggable from 'vuedraggable';
  import substepNameMixins from "@/mixins/substepNameMixins";

  export default {
    name: 'TutorialSteps',
    components: {
      draggable,
    },
    mixins: [substepNameMixins],
    props: {
      jsonData: {
        required: true,
        type: Array
      }
    },
    data: () => ({
      windowHeight: window.innerHeight,
      additionalIndent: 250,
      selectedStep: undefined,
      activeSubSteps: {},
      expandedItems: {},
    }),
    computed: {
      steps: {
        get() {
          return this.jsonData
        },
        set(newValue) {
          this.$emit('update:jsonData', newValue);
        }
      },
      listHeight() {
        return this.windowHeight - this.additionalIndent;
      },
    },
    watch: {
      selectedStep(newValue) {
        if (newValue === undefined) {
          this.$emit('unselect', newValue);
        } else {
          if (this.activeSubSteps[newValue] === undefined) {
            this.selectSubStep(0);
          }
          this.$emit('selectSubStep', newValue, this.activeSubSteps[newValue]);
        }
      },
    },
    mounted() {
      this.recalculateHeight();
    },
    updated() {
      this.recalculateHeight();
    },
    created() {
      window.addEventListener('resize', this.recalculateHeight);
    },
    destroyed() {
      window.removeEventListener('resize', this.recalculateHeight);
    },
    methods: {
      /**
       * Recalculate height of window.
       */
      recalculateHeight() {
        this.windowHeight = window.innerHeight;
      },
      
      /**
       * Add new step.
       */
      addStep() {
        this.steps.push({
          "StepName": "New step",
          "Title": "",
          "Message": "",
          "Notes": "",
          "SubSteps": [],
        });
        this.selectStep(this.steps.length-1);
      },

      /**
       * Select step by index in array of steps.
       * 
       * @param {Number} index - Index Step in array
       */
      selectStep(index) {
        this.selectedStep = index;
      },

      /**
       * Select step by index in array of steps.
       *
       * @param {Number} index - Index of SubStep in array
       */
      selectSubStep(index) {
        const activeSubSteps = {...this.activeSubSteps};
        activeSubSteps[this.selectedStep] = index;
        this.activeSubSteps = activeSubSteps;
      },

      /**
       * Moving Step handler.
       *
       * @param {Object} dragData
       */
      moveStep(dragData) {
        if (this.selectedStep !== undefined) {
          if (this.selectedStep === dragData.moved.oldIndex) {
            this.selectedStep = dragData.moved.newIndex;
          } else if (dragData.moved.newIndex <= this.selectedStep
              && dragData.moved.oldIndex > this.selectedStep
          ) {
            this.selectedStep++;
          } else if (dragData.moved.newIndex >= this.selectedStep
              && dragData.moved.oldIndex < this.selectedStep) {
            this.selectedStep--;
          }
        }
      },

      /**
       * Handler of click by Substep
       *
       * @param {Number} index - Index of clicked SubStep
       */
      clickSubStep(index) {
        const selected = index !== this.activeSubSteps[this.selectedStep];
        if (selected) {
          this.$emit('selectSubStep', this.selectedStep, index);
        } else {
          this.$emit('selectStep', this.selectedStep);
        }
      }
    }
  }
</script>

<style lang="scss">
  .tutorial-steps {
    .v-list-item__icon {
      margin-right: 5px !important;
    }
    .v-list-group__header__append-icon {
      min-width: 24px !important;
      margin-left: 6px !important;
    }
    
    .items-list {
      overflow-y: scroll;
    }
  }
</style>